import axios from 'axios';
import Cookies from 'js-cookie';

axios.interceptors.request.use(function(config) {
  const token = Cookies.get('user-token');
  token && (config.headers.Authorization = token);
  config.baseURL =
    window.location.hostname === 'searchscraper.io' ? 'https://api.searchscraper.io' : 'https://api.searchscraper.io';

  //: 'https://api.searchscraper.io';
  config.headers['Content-Type'] = 'application/json';
  return config;
});

export const isValidEmail = mail => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );
};

export const isValidDomain = (domain) =>
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    domain,
  );

export const getDataRequest = async (toolName, settings) => {
  try {
    const result = await axios(`/scraper/check`, {
      method: 'POST',
      data: {
        toolName,
        settings,
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const getUser = async () => {
  try {
    const result = await axios(`/user/get`, {
      method: 'GET',
    });
    return result && result.data && result.data.id ? result.data : {};
  } catch (error) {
    return {error};
  }
};
export const login = async lang => {
  try {
    const result = await axios(`/user/log-in/${lang}`, {
      method: 'GET',
    });
    return result;
  } catch (error) {
    return error && error.response && error.response.data ? error.response.data.url : '';
  }
};
export const logOut = async () => {
  try {
    const result = await axios(`/user/logout`, {
      method: 'GET',
    });
    return result;
  } catch (error) {
    return {error};
  }
};

export const getRequestsHistory = async data => {
  try {
    const result = await axios(`/requests/list`, {
      method: 'POST',
      data,
    });
    return result && result.data ? {result: result.data.data, total: result.data.count} : null;
  } catch (error) {
    return {error};
  }
};

export const getRequestById = async id => {
  try {
    const result = await axios(`/requests/get`, {
      method: 'POST',
      data: {id},
    });
    return result && result.data ? result.data : null;
  } catch (error) {
    return {error};
  }
};

export const getPackages = async id => {
  try {
    const result = await axios(`/packages/list`, {
      method: 'GET',
    });
    return result && result.data ? result.data : [];
  } catch (error) {
    return {error};
  }
};

export const createCard = async (token, index, paymentType) => {
  try {
    const result = await axios(`/payment/create`, {
      method: 'POST',
      data: {
        source: token,
        package: index,
        payment_type: paymentType,
        return_url: paymentType === 'paypal' ? 'http://searchscraper.io' : '',
      },
    });
    return result && result.data ? result.data : false;
  } catch (error) {
    return {error: error.response};
  }
};

export const deleteAccount = async () => {
  try {
    const result = await axios(`/user/delete`, {
      method: 'GET',
    });
    return result && result.data ? result.data : false;
  } catch (error) {
    return {error: error.response};
  }
};

export const cancelDeleteAccount = async () => {
  try {
    const result = await axios(`/user/delete/cancel`, {
      method: 'GET',
    });
    return result && result.data ? result.data : false;
  } catch (error) {
    return {error: error.response};
  }
};

export const paymentCancelResume = async (type, cancelAt) => {
  try {
    const result = await axios(`/payment/cancel`, {
      method: 'POST',
      data: {
        payment_type: type,
        cancel_at_period_end: cancelAt,
      },
    });
    return;
  } catch (error) {
    return {error};
  }
};
